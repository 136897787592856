// icons
.icon {
	color: var(--textColor);
	font-family: "Material Icons", serif;
	font-style: normal;
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: "liga";
	text-align: center;
}

// loading
.loadingOverlay {
	background-color: transparentize($color: gray, $amount: 0.9);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: all 0.25s ease-out;
	opacity: 0;
	z-index: -1;

	&.active {
		opacity: 1;
		z-index: 9999;
	}

	.roller {
		display: inline-block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 80px;
		height: 80px;

		div {
			animation: roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			transform-origin: 40px 40px;

			&:after {
				content: " ";
				display: block;
				position: absolute;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background: rgb(255, 185, 81);
				margin: -4px 0 0 -4px;
			}

			&:nth-child(1) {
				animation-delay: -0.036s;

				&:after {
					top: 63px;
					left: 63px;
				}
			}

			&:nth-child(2) {
				animation-delay: -0.072s;

				&:after {
					top: 68px;
					left: 56px;
				}
			}

			&:nth-child(3) {
				animation-delay: -0.108s;

				&:after {
					top: 71px;
					left: 48px;
				}
			}

			&:nth-child(4) {
				animation-delay: -0.144s;

				&:after {
					top: 72px;
					left: 40px;
				}
			}

			&:nth-child(5) {
				animation-delay: -0.18s;

				&:after {
					top: 71px;
					left: 32px;
				}
			}

			&:nth-child(6) {
				animation-delay: -0.216s;

				&:after {
					top: 68px;
					left: 24px;
				}
			}

			&:nth-child(7) {
				animation-delay: -0.252s;

				&:after {
					top: 63px;
					left: 17px;
				}
			}

			&:nth-child(8) {
				animation-delay: -0.288s;

				&:after {
					top: 56px;
					left: 12px;
				}
			}
		}
	}

	@keyframes roller {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}

// FieldGroup
.fieldGroup {
	margin: 12px 0;

	&.inlineLabel {
		label,
		input,
		select {
			display: inline-block;
		}

		label {
			margin-right: 8px;
		}
	}

	label {
		display: block;
	}

	input,
	textarea,
	select,
	fieldset {
		display: block;
		border: 1px solid var(--textColor);
	}

	input,
	textarea,
	select {
		border-radius: calc(var(--borderRadius) / 2);
	}

	input,
	textarea {
		padding: 4px;
	}

	input {
		&.mini {
			padding-top: 0;
			padding-bottom: 0;
			width: 40px;
		}
	}

	select {
		cursor: pointer;
		padding: 2px 26px 2px 4px;
	}
}
