.postList {
	position: relative;
	line-height: 2;
	height: 100%;
	list-style: none;
	margin-bottom: $postLinks__height;
	transition: background-color 150ms ease;

	&.draggingOver {
		background-color: var(--mainColor-transparent);
	}

	&.dayPosts {
		.post {
			&:hover {
				.postLinks {
					width: 94px;
				}
			}
		}
	}

	&.unscheduledDrafts {
		.post {
			margin: 0;

			&:hover {
				.postLinks {
					width: 100%;
				}
			}
		}
	}

	.post {
		border: 2px solid transparent;
		border-radius: var(--borderRadius);
		padding-bottom: 0;
		color: var(--neutralColor);
		cursor: pointer;
		position: relative;
		transition: all 100ms ease-in-out;

		&.visible {
			visibility: visible;
		}

		&.hidden {
			visibility: hidden;
		}

		&.hovered {
			padding-bottom: $postLinks__height;
		}

		&.notransition {
			-webkit-transition: none;
			-moz-transition: none;
			-o-transition: none;
			transition: none;
		}

		&.dragging {
			&.over {
				&__unscheduled,
				&__calendar {
					width: 100%;
					flex: 0 0 100%;
				}

				&__none {
					opacity: 0.5;
				}
			}
		}

		.postLinks {
			border-radius: var(--borderRadius);
			display: flex;
			align-items: center;
			flex-wrap: nowrap;
			justify-content: center;
			min-height: $postLinks__height;
			padding: 0 2px;
			margin: 0;
			overflow: hidden;
			position: absolute;
			bottom: 0;
			right: 50%;
			transform: translateX(50%);
			width: 100%;

			button {
				&.icon {
					flex: 0;
					border-radius: 50%;
					border: 1px solid var(--textColor);
					padding: 2px;
					margin: 1px;
					font-size: 0.9rem;
					text-decoration: none;

					&:hover {
						color: black;
						background-color: var(--neutralColor-dark);
					}

					&__trash {
						&:hover {
							color: red;
						}
					}
				}
			}
		}

		.postData {
			pointer-events: none;
			margin: 0;
			padding: 4px;
			border: 2px solid transparent;
			border-radius: var(--borderRadius);
			position: relative;
			z-index: 1;
			font-size: 90%;
			overflow: hidden;

			&__title {
				display: inline-block;
				padding: 0 1px;
				width: 100%;
				margin: 0;
			}
		}

		&.currentPost {
			.postData {
				border: 2px solid var(--mainColor-light);
			}
		}
	}
}
