:root {
	--mainColor: #1a8fff;
	--mainColor-light: #53cbf1;
	--mainColor-transparent: #1a8fff24;
	--textColor: #777;
	--textColor-light: #ccc;
	--textColor-dark: #333;
	--textColor-transparent: #333;
	--borderColor: #eee;
	--bgColor: #f9f9f9;
	--neutralColor: #fff;
	--neutralColor-dark: rgb(247, 247, 247);
	--borderRadius: 8px;
	--pastColor: #654654a9;
	--gridGap: 1em;
}

$mobileBreak: 800px;
$postLinks__height: 30px;