@mixin dropAreaProps {
	content: "\0a00";
	font-size: 0.5em;
	opacity: 0;
	text-transform: uppercase;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin absoluteCenter {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
